import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import ImageNext from 'next/legacy/image';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'i18n';
import { formatDuration, formatTimeDuration } from 'utils/format';
import { useRouter } from 'next/router';
import Image from '../../Image';
import { routes } from '../../../../utils/routing';
import { getHideTimeEventIds } from '../../../../utils/customHideTime';

interface EventProps {
  event: any;
  onRedirect: () => void;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: '0 0 16px 0',
    borderBottom: `1px solid ${theme.palette.custom.gray.medium}`,
    cursor: 'pointer',
  },
  imageWrapper: {
    alignSelf: 'flex-start',
    paddingLeft: '0 !important',
  },
  imageContainer: {
    width: 118,
    height: 74,
    position: 'relative',
  },
  infoContainer: {
    alignSelf: 'flex-start',
    maxWidth: 'calc(100% - 130px)',
    paddingLeft: 20,
  },
  title: {
    fontWeight: 600,
  },
  icons: {
    display: 'flex',
  },
  dateText: {
    fontWeight: 600,
  },
  sessions: {
    textTransform: 'uppercase',
    fontWeight: 600,
  },
}));

const EventsItem: React.FC<React.PropsWithChildren<EventProps>> = (props) => {
  const { event, onRedirect } = props;
  const { id, title, organization, start_at, end_at, timezone, main_image, sessions } = event;
  const { classes } = useStyles();
  const isMultiple = sessions.length > 1;
  const [t] = useTranslation();
  const router = useRouter();
  const hideTime = useMemo(() => getHideTimeEventIds().includes(id), [id]);
  const onClick = () => {
    onRedirect();
    const route = routes.event(event.id);
    router.push(route.href);
  };

  return (
    <Grid className={classes.root} container alignItems="center" onClick={onClick}>
      <Grid item className={classes.imageWrapper}>
        <div className={classes.imageContainer}>
          <Image variant="rectangle" rounded src={main_image} alt={title} />
        </div>
      </Grid>
      <Grid item className={classes.infoContainer}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography className={classes.title} color="textPrimary" variant="body2" component="p">
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="textSecondary" variant="caption" component="p">
              {t<string>('common:general.by')} {organization.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {start_at && timezone && (
              <Grid container spacing={1}>
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item className={classes.icons}>
                      <ImageNext src="/static/img/ico-calendar.svg" alt="ico-calendar" width={15} height={15} />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.dateText} color="textPrimary" variant="body2">
                        {formatDuration(start_at, end_at, timezone, true)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {!hideTime && (
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item className={classes.icons}>
                        {sessions && (
                          <ImageNext src="/static/img/ico-clock.svg" alt="ico-clock" width={15} height={15} />
                        )}
                      </Grid>
                      <Grid item>
                        {sessions && (
                          <Grid container>
                            <Grid item>
                              <Typography className={classes.sessions} color="textPrimary" variant="body2">
                                {isMultiple && t('public:searchHeader.event.multiple')}
                                {!isMultiple &&
                                  formatTimeDuration(sessions[0].start_at, sessions[0].end_at, timezone, false)}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EventsItem;
